import React, {useState} from 'react';
import './AttributeGroup.scss';
import '../Attribute/attribute.scss';
// Images
import Check from '../../../../images/check-icon.svg';

const AttributeGroup = ({group, onToggle}) => {

    const [selected, setSelected] = useState(null);

    function selectAttribute(id) {
        onToggle(selected, id);
        setSelected(id);
    }

    const Attributes = () => group.attributes.map(attribute =>
        <div id={`attribute_${attribute.id}`} className={`attribute not-animated ${selected === attribute.id && 'selected'}`}
            key={attribute.id}
            onClick={() => selectAttribute(attribute.id)}>

            {attribute.name}
            <div className="checkbox round">
                {selected === attribute.id && <img src={Check} alt=""/>}
            </div>
        </div>
    );

    return (
        <div id={`attribute_group_${group.id}`} className="attribute-group">
            <div className="attribute-group-title">{group.title}</div>
            <Attributes />
        </div>
    )
}

export default AttributeGroup;