import React from 'react';
import './Intro.scss';

import Button from '../../../../shared/components/Button/Button';
// Icons
import { ReactComponent as Shield } from '../../../../images/shield.svg';
import { ReactComponent as User } from '../../../../images/user.svg';
import { ReactComponent as Cloud } from '../../../../images/cloud.svg';

const Intro = ({onStart}) => {

    const Step = ({children, step}) => {

        return (
            <div className="step">
                <span className="stepNumber">{step}</span>
                {children}
            </div>
        )
    }

    const IconBox = ({children, name}) => {
        return (
            <div className="iconBox">
                <div className="iconBox-box">
                    {children}
                </div>
                <div>{name}</div>
            </div>
        )
    }

    const Icons = () => {
        return (
            <div className="introIcons">
                <IconBox name="Personalisiert">
                    <User />
                </IconBox>
                <IconBox name="Jederzeit abrufbar">
                    <Cloud />
                </IconBox>
                <IconBox name="Keine Erfassung von Personendaten">
                    <Shield />
                </IconBox>
            </div>
        )
    }

    return (
        <div className="intro small-container flex">
            <h1>
                Personalisierte Argumente für ein NEIN zur Unternehmensverantwortungs-Initiative – Ihre Unterstützung ist entscheidend
            </h1>
            <p>
                Sie treten bald an einer Podiumsdiskussion auf, debattieren politische Themen im Freundeskreis, schreiben gerne Leserbriefe oder möchten sich einfach nur informieren? Dann stellen wir für Sie in ein paar wenigen Schritten und Sekunden eine ganz auf Ihre Bedürfnisse und Situation zugeschnittene Begründung für ein NEIN zur Unternehmensverantwortungs-Initiative zusammen.
            </p>

            <h3>
                Mit wenigen Klicks zum persönlichen Argumentarium gegen die Unternehmensverantwortungs-Initiative
            </h3>

            <div className="steps">
                <Step step={1}>
                    Klicken Sie sich durch die sechs Standardfragen
                </Step>
                <Step step={2}>
                    Ihr persönliches Argumentarium wird in wenigen Sekunden für Sie erstellt
                </Step>
                <Step step={3}>
                    Laden Sie die Argumente auf Ihr Gerät herunter
                </Step>
            </div>

            <Button onClick={() => onStart()}>Jetzt beginnen</Button>

            <Icons />
        </div>
    )
}

export default Intro;
