import { createContext } from 'react';
import { observable, action, computed, toJS } from 'mobx';

import Service from '../services/Services';

class Store {
    constructor() {
        this.service = new Service();
    }

    history;

    @observable categories = [];
    @observable loading = false;
    @observable currentCategory = 0;
    @observable totalCategories = 1;
    @observable attributes = [];

    @observable arguments = [];
    @observable weblinks = [];
    @observable argumentCode = null;
    @observable copied = false;

    @observable errorMessage = '';
    
    /* 
    --------------- CATEGORIES LOGIC ---------------
    */

    @action getCategories() {
        this.service.getCategories().then(response => {
            console.log('CATEGORIES', response.data);
            this.categories = response.data;
            this.totalCategories = response.data.length;
            this.loading = false;
        })
    }

    @action nextCategory() {
        if (this.isLast) return;

        this.currentCategory++;
    }

    @action prevCategory() {
        if (this.currentCategory === 0) return;

        this.currentCategory--;
    }

    /* 
    --------------- ARGUMENTS LOGIC ---------------
    */

    @action generateArguments() {
        if (this.attributes.length === 0) {
            this.showError("Bitte wählen Sie mindestens ein Attribut aus.")
            return;
        }
        this.loading = true;
        this.service.createArgumentarium({attributes: this.attributes}).then(response => {
            console.log(response.data);
            this.arguments = response.data.arguments;
            this.weblinks = response.data.weblinks;
            this.argumentCode = response.data.code;
            this.loading = false;
            this.history.push(`/argumentarium/${response.data.code}`);
        }).catch(e => console.error(e));
    }

    @action getArguments(code) {
        this.service.getArgumentarium(code).then(response => {
            console.log(response.data);
            this.arguments = response.data.arguments;
            this.weblinks = response.data.weblinks;
            this.argumentCode = response.data.code;
        }).catch(e => this.handleError(e))
    }

    @action rateArgument(id, rating) {
        const body = {
            argument: id,
            rating: rating,
            argumentarium: this.argumentCode
        }
        console.log(body);
        this.service.rateArgument(body).then(response => {
            console.log(response);
            const argument = this.arguments.find(arg => arg.id === id);
            argument.is_rated = true;
        })
    }

    @action copyArgumentariumCode() {
        this.copied = true;

        setTimeout(() => this.copied = false, 3000);
    }

    /* 
    --------------- ADD/REMOVE ATTRIBUTES ---------------
    */

    @action toggleAttribute(selected, id) {
        selected ? this.removeAttribute(id) : this.addAttribute(id);
    }

    @action addAttribute(id) {
        this.attributes.push(id);
        console.log('ATTRIBUTES', toJS(this.attributes));
    }

    @action removeAttribute(id) {
        const index = this.attributes.findIndex(attribute => attribute === id);
        this.attributes.splice(index, 1);
        console.log(toJS(this.attributes));
    }

    @computed get selectedCategory() {
        return this.categories[this.currentCategory];
    }
    @computed get isLast() {
        return this.currentCategory === this.totalCategories - 1;
    }

    @action resetCategories() {
        this.currentCategory = 0;
        this.totalCategories = 1;
    }

    /* 
    --------------- ERROS ---------------
    */

    handleError(error) {
        this.history.push('/not-found');
    }

    showError(message) {
        this.errorMessage = message;

        setTimeout(() => this.errorMessage = '', 3000);
    }
}

export default createContext(new Store());