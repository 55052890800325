import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import './categories.scss';
// Stores
import Store from '../../core/stores/Store';
// Compopnents
import Attribute from './components/Attribute/Attribute';
import AttributeGroup from './components/AttributeGroup/AttributeGroup';
import Intro from './components/Intro/Intro';
import Button from '../../shared/components/Button/Button';
import Loader from '../../shared/components/Loader/Loader';
import Header from '../../shared/components/Header/Header';
import Footer from '../../shared/components/Footer/Footer';
import Notification from '../../shared/components/Notification/Notificaton';
import CategoriesContainer from '../../shared/components/CategoriesContainer/CategoriesContainer';
// Images
import BackIcon from '../../images/back.svg';

const Categories = observer(() => {

    const history = useHistory();
    const store = useContext(Store);
    const [showIntro, setIntro] = useState(true);

    useEffect(() => {
        store.getCategories();
        store.history = history;

        return () => {
            store.resetCategories();
        }
    }, [history, store])

    const Attributes = () =>
        store.selectedCategory.attributes.map(attribute =>
            <Attribute
                id={attribute.id}
                text={attribute.name}
                onToggle={(selected) => store.toggleAttribute(selected, attribute.id)}
                key={attribute.id} />
        );

    const AttributesGroup = () => 
        store.selectedCategory.attribute_groups.map(group =>
            <AttributeGroup 
                group={group} 
                key={group.id} 
                onToggle={(remove, add) => handleGroup(remove, add)}/>
        );

    const handleClick = () => {
        store.isLast ? store.generateArguments() : store.nextCategory();
        window.scrollTo(0,0);
    }

    const handleGroup = (removeID, addID) => {
        if (removeID === addID) return;
        if (removeID) store.removeAttribute(removeID);
        if (addID) store.addAttribute(addID);
    }

    const BackButton = () => {
        if (store.currentCategory === 0) return null;

        return (
            <img className="backButton" src={BackIcon} alt="Back icon" onClick={() => store.prevCategory()}/>
        )
    }

    if (store.categories?.length === 0) return null;

    return (
        <>
            <CategoriesContainer>
                <Header />

                {showIntro ?
                <Intro onStart={() => setIntro(false)}/> :


                <div id={`category_${store.selectedCategory.id}`} className="categories small-container flex">
                    <h1>
                        {store.selectedCategory.criteria}
                        <BackButton />
                    </h1>
                    <p>{store.selectedCategory.description}</p>

                    <AttributesGroup />
                    <Attributes />

                    <div className="buttonWrapper">
                        <Button onClick={() => handleClick()}>{store.isLast ? 'Argumente erhalten' : 'Weiter'}</Button>
                    </div>

                </div>
                }

                <Footer />
            </CategoriesContainer>
            
            {store.loading && <Loader />}

            <Notification />
        </>
    )
})

export default Categories;