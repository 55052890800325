import React, { useState, useEffect } from 'react';
import './argumentRating.scss';

// Images
import { ReactComponent as StarIcon } from '../../../../images/star.svg';
import { observer } from 'mobx-react';

const ArgumentRating = observer(({isRated, onRate, open}) => {

    const [showFeedback, setShowFeedack] = useState(false);
    const [wasRated, setWasRated] = useState(false);

    useEffect(() => {
        setWasRated(isRated);
    }, [isRated])

    useEffect(() => {
        hideRating();
    })

    async function hideRating() {
        if (isRated) {
            setShowFeedack(true);
            await new Promise(r => setTimeout(r, 4000));
            setWasRated(true);
        }
    }

    function rateArgument(rate) {
        if (isRated) return;
        onRate(rate);
    }

    const Star = ({rate}) => {
        return (
            <button onClick={() => rateArgument(rate)}>
                <StarIcon />
            </button>
        )
    }

    if (wasRated && isRated) return null

    return (
            <div className={`argumentRating ${showFeedback ? 'rated' : ''}`}>
                <div>
                    Wie nützlich war dieses Argument für Sie?
                    <div className="stars">
                        <Star rate={1} />
                        <Star rate={2} />
                        <Star rate={3} />
                        <Star rate={4} />
                        <Star rate={5} />
                    </div>
                </div>
                <div className="thankYou" onClick={() => rateArgument(1)}>Thank you for your feedback!</div>
            </div>
    )
})

export default ArgumentRating;