import React from 'react';
import ReactPixel from 'react-facebook-pixel';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

// Components
import Categories from '../../views/Categories/Categories';
import Arguments from '../../views/Arguments/Arguments';
import Impressum from '../../views/Impressum/Impressum';
import NotFound from '../../views/NotFound/NotFound';

// Routes
export const baseUrl = '/';
export const argumentsUrl = `${baseUrl}argumentarium/:id`;
export const impressumUrl = `${baseUrl}impressum`;
export const notFoundUrl = `${baseUrl}not-found`;

const withAnalytics = (Component) => (props) => {
    ReactPixel.pageView();
    return <Component {...props} />;
};

const Routes = () => {

    return (
        <Router>
            <Switch>
                <Route exact path={baseUrl} component={ withAnalytics(Categories) }/>
                <Route path={argumentsUrl} component={ withAnalytics(Arguments) }/>
                <Route path={impressumUrl} component={ withAnalytics(Impressum) }/>
                <Route path={notFoundUrl} component={ withAnalytics(NotFound) }/>
            </Switch>
        </Router>
    )

}

export default Routes;
