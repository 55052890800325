import React, { useState } from 'react';
import './attribute.scss';
// Images
import Check from '../../../../images/check-icon.svg';

const Attribute = ({id, text, onToggle}) => {

    const [selected, setSelected] = useState(false);

    const selectAttribute = () => {
        setSelected(!selected);
        onToggle(selected);
    }

    return (
        <div id={`attribute_${id}`} className={`attribute ${selected && 'selected'}`} onClick={() => selectAttribute()}>
            {text}
            <div className="checkbox">
                {selected && <img src={Check} alt=""/>}
            </div>
        </div>
    )
}

export default Attribute;
