import React from 'react';
import { useHistory } from 'react-router-dom';
import './notFound.scss'
// Componnts
import CategoriesContainer from '../../shared/components/CategoriesContainer/CategoriesContainer';
import Header from '../../shared/components/Header/Header';
import Footer from '../../shared/components/Footer/Footer';
import Button from '../../shared/components/Button/Button';

import Error404 from '../../images/404-error.svg';

const NotFound = () => {

    const history = useHistory();

    function goHome() {
        history.push('/');
    }

    return (
        <CategoriesContainer>
            <Header />
            <div className="notFound">
                <img src={Error404} alt="404"/>

                <div class="notFound-text">
                    <div>Sorry! We can’t find the results you are looking for!</div>
                    <div>Go to the previous page and try again.</div>
                </div>

                <div className="notFound-button">
                    <Button onClick={() => goHome()}>Back</Button>
                </div>
            </div>
            <Footer />
        </CategoriesContainer>
    )
}

export default NotFound;