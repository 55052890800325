import React, { useEffect, useState } from 'react';
import './argumentRelevance.scss';

const ArgumentRelevance = ({perc}) => {

    // Progress circle is set to 0 when the stroke value is as 376.99 (circle's circumference).
    // As we are using half of the circle, our max value would be half of that
    // which is 188.495. That's our 100% relevance
    const min = 376.99;
    const max = 188.495;

    const [stroke, setStroke] = useState(min);
    const [rotate, setRotate] = useState(0);
    const [color, setColor] = useState(null);
    const [gradient, setGradient] = useState(null);

    useEffect(() => {
        const strokeBar = (max / 100) * perc;
        const lightness = 20 + (0.35 * perc);
        
        setColor(`hsl(154, 74%, ${lightness}%)`);
        setGradient(`linear-gradient(hsla(154, 74%, ${lightness}%, 0.7), rgba(198, 208, 230, 0) 50%)`);
        setStroke(min - strokeBar);
        // Rotation of our arrow is set by using the maximum of 180 degrees (half circle)
        setRotate(perc * 1.8);
    }, [perc])

    return (
        <div className="argumentRelevance">
            <div className="argumentRelevance-fade" style={{backgroundImage: gradient}}></div>
            <div className="argumentRelevance-inner">
                <div className="argumentRelevance-arrow" style={{transform: `rotate(${rotate}deg)`}}>
                    <div className="triangle"></div>
                    <div className="dot" style={{color: color}}>
                        <span className="point" style={{background: color}}></span>
                    </div>
                </div>
                <div className="argumentRelevance-info">
                    <h3>{perc}%</h3>
                    Relevanz
                </div>
            </div>
            <svg id="svg" width="130" height="130" className="progress-ring">
                <circle r="60" cx="65" cy="65" stroke={color} strokeDasharray={min} strokeDashoffset={stroke}></circle>
            </svg>
        </div>
    )
}

export default ArgumentRelevance;