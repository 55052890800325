import React from 'react';

// Images
// import Blue from '../../../images/bg-blue.svg';
import { ReactComponent as Blue } from '../../../images/bg-blue.svg';
import { ReactComponent as Gray } from '../../../images/bg-gray.svg';

const ArgumentsContainer = ({children}) => {

    return (
        <section className="arguments-bg">
            <Blue className="bg-element" style={{top: 105, right: 125}} />
            <Blue className="bg-element" style={{top: 200, right: 190}} />
            <Gray className="bg-element accent" style={{top: 1090, right: 170}} />
            <Blue className="bg-element" style={{top: 595, left: 170}} />
            <Gray className="bg-element accent" style={{top: 690, left: 130}} />
            <div className="container flex">
                {children}
            </div>
        </section>
    )

}

export default ArgumentsContainer;