import React from 'react';
import './Impressum.scss';
// Components
import ArgumentsContainer from '../../shared/components/ArgumentsContainer/ArgumentsContainer';
import Header from '../../shared/components/Header/Header';
import Footer from '../../shared/components/Footer/Footer';

const Impressum = () => {
	return (
		<>
			<ArgumentsContainer>
				<Header />
				<div className="impressum">
					<h1>Imressum und Datenschutz</h1>

					<h2>Inhaberin dieser Website, Redaktion und inhaltliche Umsetzung</h2>
					<p>
						Die Nutzung dieser Webseite ist ohne Angabe von personenbezogenen Daten möglich. Ihre Angaben
						werden anonym ausgewertet und nicht an Dritte weitergegeben. Ein Rückschluss auf Ihre Person ist
						nicht möglich.
					</p>

					<p>
						succèSuisse <br />
						c/o furrerhugi. ag <br />
						Schauplatzgasse 39 <br />
						3011 Bern <br />
						<br />
						info@succesuisse.ch <br />
						+41 31 313 18 48
					</p>

					<div className="divider" />

					<h2>Datenschutzerklärung</h2>
					<p>
						Der Schutz Ihrer Privatsphäre ist uns ein wichtiges Anliegen. Die Verarbeitung von
						personenbezogenen Daten erfolgt basierend auf dem Schweizerischen Datenschutzgesetz (DSG) und
						der europäischen Datenschutzgrundverordnung (DSGVO).
					</p>
					<p>
						Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter, wenn
						dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.
					</p>
					<p>
						Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu
						bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre
						E-Mail-Adresse und Telefonnummer.
					</p>
					<p>Die Nutzung dieser Webseite ist ohne Angabe von personenbezogenen Daten möglich.</p>

					<h3>Server-Logfiles</h3>

					<p>
						Wir erfassen für die korrekte Bereitstellung der Webseite notwendige technische Angaben
						(Zugriffsdaten) wie die IP-Adresse, der verwendete Browser, das verwendete Betriebssystem, Datum
						und Zeitpunkt des Zugriffs, aufgerufene Seite, Header-Daten des Browsers. Um den Betrieb des
						Webservers sicherzustellen und Missbrauchsfälle zu erkennen, werden die Zugriffsdaten ohne
						Personenbezug in Logfiles gespeichert.
					</p>

					<h3>Kontaktaufnahme</h3>

					<p>
						Nehmen Sie mit uns durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden Ihre
						Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
						zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte
						weitergegeben.
					</p>

					<h3>Cookies</h3>
					<p>
						Wir verwenden auf unserer Website Cookies, die notwendig sind, damit wir Ihnen die Nutzung
						bestimmter Funktionen ermöglichen können (z. B. Sprachwahl). Cookies sind Textdateien, die auf
						Ihren Computer oder auf Ihr mobiles Gerät heruntergeladen werden.
					</p>
					<p>
						Indem Sie die Einstellungen Ihres Webbrowsers entsprechend anpassen, können Sie verhindern, dass
						Cookies auf Ihrem Gerät gespeichert werden. Ausserdem können Sie bereits gespeicherte Cookies
						jederzeit löschen. Beachten Sie jedoch, dass einige Funktionen der Website eingeschränkt oder
						nicht verfügbar sein können, wenn Sie das Speichern von Cookies deaktivieren.
					</p>

					<h3>Externe Dienste</h3>

					<p>Wir verwenden zur effizienten Bereitstellung dieser Webseite die folgenden externen Dienste:</p>
					<ul>
						<li>
							Google Fonts: Zur einheitlichen Darstellung dieser Webseite in allen Browsern binden wir
							Schriftarten via Google Fonts ein. Hierzu werden zum effizienten Betrieb notwendige Daten an
							Google gesendet. Informationen dazu finden Sie{' '}
							<a
								href="https://developers.google.com/fonts/faq/#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users"
								target="_blank"
								rel="noopener noreferrer"
							>
								hier
							</a>. Sie haben die Möglichkeit, in Ihrem Browser das Laden von externen Schriftarten
							auszuschalten.
						</li>
					</ul>

					<h3>Rechte der Nutzer</h3>
					<p>
						Sie haben das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche
						personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf
						Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer
						personenbezogenen Daten. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden,
						können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
					</p>
					<p>
						Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person
						gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder
						Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten,
						wenden Sie sich bitte an folgende E-Mail-Adresse:{' '}
						<a href="mailto:info@succesuisse.ch">info@succesuisse.ch</a>
					</p>

					<div className="divider" />

					<h2>Nutzungsbestimmungen</h2>

					<h3>Allgemeiner Haftungsausschluss</h3>

					<p>
						Alle Angaben auf unserer Website wurden sorgfältig geprüft. Wir bemühen uns, unser
						Informationsangebot aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann das
						Auftreten von Fehlern nicht ausgeschlossen werden, womit wir keine Garantie für Vollständigkeit,
						Richtigkeit und Aktualität von Informationen auch journalistisch-redaktioneller Art übernehmen
						können. Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch die Nutzung der
						angebotenen Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich
						vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
					</p>

					<p>
						Wir können nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und sind
						nicht verpflichtet, Inhalte zu aktualisieren. Die Benutzung der Website geschieht auf eigene
						Gefahr des Besuchers. Der Herausgeber, seine Auftraggeber oder Partner sind nicht verantwortlich
						für Schäden, wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder Folgeschäden,
						die angeblich durch den Besuch unserer Website entstanden sind und übernehmen hierfür folglich
						keine Haftung.
					</p>

					<h3>Urheberrecht</h3>
					<p>
						Falls nicht anders gekennzeichnet, sind alle Inhalte dieser Webseite geistiges Eigentum der
						Inhaberin. Eine Nutzung ohne vorgängige Einwilligung ist nicht gestattet.
					</p>
				</div>
			</ArgumentsContainer>
			<Footer />
		</>
	);
};

export default Impressum;
