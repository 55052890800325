import React from 'react';
import './header.scss';
// Images
import Logo from '../../../images/logo.svg';
import LogoUVI from '../../../images/kvi.png';


const Header = () => {

    return (
        <div className="header">
            <img src={Logo} alt="Argumentor Logo"/>
            <img className="bigLogo" src={LogoUVI} alt="UVI Logo"/>
        </div>
    )
}

export default Header;
