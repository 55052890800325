import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import './Notification.scss';
// Store
import Store from '../../../core/stores/Store';

const Notification = observer(() => {

    const store = useContext(Store);

    if (!store.errorMessage) return null;

    return (
        <div className="notification">
            {store.errorMessage}
        </div>
    )
})

export default Notification;