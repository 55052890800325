import React, { useEffect, useState } from 'react';
import './weblinkCard.scss';

import Arrow from '../../../../images/arrow.svg';

const WeblinkCard = ({weblink}) => {

    const [color, setColor] = useState(null);

    useEffect(() => {
        const lightness = 20 + (0.35 * weblink.relevance);
        setColor(`hsl(154, 74%, ${lightness}%)`);
    }, [weblink.relevance]);

    return (
        <a className={`weblinkCard ${weblink.is_new && 'new'}`} href={weblink.url} target="_blank" rel="noopener noreferrer">
            <div className="weblinkCard-img" style={{backgroundImage: `url(${weblink.image})`}}>
                <div className="weblinkCard-relevance" style={{backgroundColor: color}}>
                    {weblink.relevance}% Relevanz
                </div>
                <div className="weblinkCard-arrow">
                    <img src={Arrow} alt="Arrow icon"/>
                </div>
            </div>
            <div className="weblinkCard-info">
                <h2>{weblink.website}</h2>
                <p className="weblinkCard-text">{weblink.text}</p>
                <p className="weblinkCard-date">{weblink.date}</p>
            </div>
        </a>
    )
}

export default WeblinkCard;