import React from 'react';

// Images
import { ReactComponent as Blue } from '../../../images/bg-blue.svg';
import { ReactComponent as Gray } from '../../../images/bg-gray.svg';

const CategoriesContainer = ({children}) => {

    return (
        <section className="categories-bg">
            <Blue className="bg-element" style={{top: 170, left: 100}} />
            <Blue className="bg-element" style={{top: 545, left: 260}} />
            <Gray className="bg-element accent" style={{top: 640, left: 200}} />
            <Blue className="bg-element" style={{top: 300, right: 200}} />
            <Gray className="bg-element accent" style={{top: 625, right: 260}} />

            {children}
        </section>
    )
}

export default CategoriesContainer;