import React, { useContext } from 'react';
import './argumentLink.scss';
// Store
import Store from '../../../../core/stores/Store';
import { observer } from 'mobx-react';

const ArgumentLink = observer(({children}) => {

    const {copied} = useContext(Store);
    
    return (
        <div className={`argumentLink ${copied && 'copied'}`}>
            <div className="argumentLink-content">
                <div id="argumentUrl">{children}</div>
                <div>Adresse kopiert</div>
            </div>
        </div>
    )
})

export default ArgumentLink;