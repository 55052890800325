import React from 'react';
import './argumentsHeader.scss';
// Components
import Header from '../../../../shared/components/Header/Header';

const ArgumentsHeader = () => {

    return (
        <div className="argumentsHeader">
            <div className="container">
                <Header />
                <h1>Ihr persönliches Argumentarium für ein NEIN zur Unternehmensverantwortungs-Initiative</h1>
            </div>
        </div>
    )
}

export default ArgumentsHeader;
