import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {observer} from 'mobx-react';
import './arguments.scss';
import { jsPDF } from 'jspdf';
// Components
import ArgumentsHeader from './components/ArgumentsHeader/ArgumentsHeader';
import ArgumentCard from './components/ArgumentCard/ArgumentCard';
import WeblinkCard from './components/WeblinkCard/WeblinkCard';
import ArgumentsContainer from '../../shared/components/ArgumentsContainer/ArgumentsContainer';
import Footer from '../../shared/components/Footer/Footer';
import Button from '../../shared/components/Button/Button';
// Store
import Store from '../../core/stores/Store';

import $ from "jquery";
import ArgumentLink from "./components/ArgumentLink/ArgumentLink";
import ArgumentActions from "./components/ArgumentActions/ArgumentActions";


const Arguments = observer(() => {

    const { id } = useParams();
    const store = useContext(Store);
    const [tab, setTab] = useState('arguments');
    const history = useHistory()
    const [download, setDownload] = useState(false);

    useEffect(() => {
        if (id) store.getArguments(id);
        store.history = history;

        var $cr = $.noConflict();
            $cr(".cr_form").submit(function(e) {
                $cr(this).find('.clever_form_error').removeClass('clever_form_error');
                $cr(this).find('.clever_form_note').remove();
                $cr(this).find(".musthave").find('input, textarea').each(function() {
                    if ($.trim($cr(this).val()) === "" || ($cr(this).is(':checkbox')) || ($cr(this).is(':radio'))) {
                        if ($cr(this).is(':checkbox') || ($cr(this).is(':radio'))) {
                            if (!$cr(this).parent().find(":checked").is(":checked")) {
                                $cr(this).parent().addClass('clever_form_error')
                            }
                        } else {
                            $cr(this).addClass('clever_form_error')
                        }
                    }
                });
                if ($cr(this).attr("action").search(document.domain) > 0 && $cr(".cr_form").attr("action").search("wcs") > 0) {
                    var cr_email = $cr(this).find('input[name=email]');
                    var unsub = false;
                    if ($cr("input['name=cr_subunsubscribe'][value='false']").length) {
                        if ($cr("input['name=cr_subunsubscribe'][value='false']").is(":checked")) {
                            unsub = true
                        }
                    }
                    if (cr_email.val() && !unsub) {
                        $cr.ajax({
                            type: "GET",
                            url: $cr(".cr_form").attr("action").replace("wcs", "check_email") + $cr(this).find('input[name=email]').val(),
                            success: function(data) {
                                if (data) {
                                    cr_email.addClass('clever_form_error').before("<div class='clever_form_note cr_font'>" + data + "</div>");
                                    return false
                                }
                            },
                            async: false
                        })
                    }
                }
                if ($cr(this).find('.clever_form_error').length) {
                    return false
                }
                return true
            });
            $cr('input[class*="cr_number"]').change(function() {
                console.log("wat")
                if (isNaN($cr(this).val())) {
                    $cr(this).val(1)
                }
                if ($cr(this).attr("min")) {
                    if (($cr(this).val() * 1) < ($cr(this).attr("min") * 1)) {
                        $cr(this).val($cr(this).attr("min"))
                    }
                }
                if ($cr(this).attr("max")) {
                    if (($cr(this).val() * 1) > ($cr(this).attr("max") * 1)) {
                        $cr(this).val($cr(this).attr("max"))
                    }
                }
            });



    }, [id, history, store])

    const renderWeblinks = () => {
        return (
            <div className="weblinks">
                {store.weblinks.map((weblink) => <WeblinkCard weblink={weblink} key={weblink.id}/>)}
            </div>
        )
    }

    const getLink = () => {
        const host = window.location.origin;
        if (store.argumentCode) return `${host}/argumentarium/${store.argumentCode}`;
        return `${host}/argumentarium/`
    }

    const argumentsList = store.arguments.map((argument) => <ArgumentCard argument={argument} key={argument.id}/>);


    const cleanValue = (text) => {
        let cleanValue = text.replace(/’/g, "'");
        cleanValue = cleanValue.replace(/•/g, "-");
        cleanValue = cleanValue.replace(/…/g, "...");
        return cleanValue
    }

    const PritnableArguments = () => {
        return (
            <div id="printable">
                <h2 style={{"textAlign":"center"}}>Ihr persönliches Argumentarium für ein NEIN zur Unternehmensverantwortungs-Initiative</h2>
                {store.arguments.map((argument) =>
                    <div key={argument.id} style={{'pageBreakAfter': 'always', 'height': '200px'}}>
                        <h2 style={{'pageBreakAfter': 'always'}}>{argument.title}</h2>
                        <h4 style={{'pageBreakAfter': 'always'}}>Relevanz: {argument.relevance}%</h4>
                        <p style={{'pageBreakAfter': 'always', 'whiteSpace': 'no-wrap'}}>{cleanValue(argument.description)}</p>
                    </div>
                )}
            </div>
        )
    }

    async function downloadPDF() {

        await setDownload(true);

        const pdf = new jsPDF('p', 'pt', 'a4');

        pdf.html($('#printable').get(0), {
            callback: function(doc) {
                doc.save('argumente');
                setDownload(false);
            },
            x: 20,
            y: 20,
            width: 500
        });

    }

    return (
        <>
            <ArgumentsHeader code={store.argumentCode}/>
            <ArgumentsContainer>
                <div className="arguments">
                    <div className="tabs">
                        <div className={`tab ${tab === 'arguments' ? 'active' : ''}`} onClick={() => setTab('arguments')}>Ihre Argumente</div>
                        <div className={`tab ${tab === 'weblinks' ? 'active' : ''}`} onClick={() => setTab('weblinks')}>Ihre Weblinks</div>
                    </div>
                    {tab === 'arguments' && argumentsList}

                    {tab === 'weblinks' && renderWeblinks()}
                    <div className="downloadButton">
                        <Button onClick={() => downloadPDF()}>PDF herunterladen</Button>
                    </div>
                    <form className="layout_form cr_form cr_font"
                          action="http://269003.249668.eu2.cleverreach.com/f/269003-266674/wcs/" method="post"
                          target="_blank">
                        <div className="cr_body cr_page cr_font formbox">
                            <div className="editable_content">
                                <h2 className="cr_ipe_item">Newsletter</h2>

                                <div id="5810412" rel="email" className="cr_ipe_item musthave" style={{"width": "40%", "zIndex": "999",  "float":"left"}}>
                                    <input id="text5810412" className="email-field" name="email" placeholder="Email*" type="text"/>
                                </div>
                                <div id="5810414" rel="button" className="cr_ipe_item cr_button_container ui-sortable submit_container">
                                    <button type="submit" className="cr_button">Anmelden</button>
                                </div>
                                <br />
                                <div id="5810707" rel="checkbox" className="cr_ipe_item ui-sortable">
                                    <label className="itemname"></label>
                                    <div>
                                        <input
                                            id="Ja, schicken Sie mir eine Nachricht wenn es neue Argumente gibt. 5810707"
                                            className="cr_ipe_checkbox" name="1129190[]"
                                            value="Ja, schicken Sie mir eine Nachricht wenn es neue Argumente gibt. "
                                            type="checkbox"/>Ja, schicken Sie mir eine Nachricht wenn es neue Argumente
                                        gibt. &nbsp;
                                    </div>
                                </div>
                                <div id="5810708" rel="checkbox" className="cr_ipe_item ui-sortable">
                                    <label className="itemname"></label>
                                    <div>
                                        <input id="Ja, ich will über künftige Kampagnen informiert werden. 5810708"
                                               className="cr_ipe_checkbox" name="1129191[]"
                                               value="Ja, ich will über künftige Kampagnen informiert werden. "
                                               type="checkbox"/>Ja, ich will über künftige Kampagnen informiert
                                        werden. &nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <ArgumentLink>{getLink()}</ArgumentLink>
                    <ArgumentActions />
                </div>
                <Footer />
            </ArgumentsContainer>
            {download && <PritnableArguments />}
        </>
    )
})

export default Arguments;
