import React from 'react';
import './footer.scss';

import Logo from '../../../images/succe.svg';

const Footer = () => {

    return (
        <div className="footer">
            <div className="footer-content">
                <div>Argumentor.ch 2020. All rights reserved</div>
                <img src={Logo} alt="Footer logo"/>
                <a href="/impressum">Impressum und Datenschutz</a>
            </div>
        </div>
    )
}

export default Footer;
