import React from 'react';
import './loader.scss';
import Spinner from '../Spinner/Spinner';

const Loader = () => {

    return (
        <div className="loader">
            <Spinner />
            <div>Argumente werden vorbereitet...</div>
        </div>
    )
}

export default Loader;