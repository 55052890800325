import React, { useContext } from 'react';
import './argumentActions.scss';
import bookmarkPage from '../../../../core/helpers';
// Images
import Copy from '../../../../images/copy.svg';
import Bookmark from '../../../../images/bookmark.svg';
// Store
import Store from '../../../../core/stores/Store';

const ArgumentActions = () => {

    const store = useContext(Store);

    function copyCode() {
        const range = document.createRange();
        range.selectNode(document.getElementById("argumentUrl"));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect
        store.copyArgumentariumCode();
    }

    return (
        <div className="argumentActions">
            <div className="argumentActions-info">
                Der Argumentator hat nun aufgrund Ihrer Angaben ein auf Sie persönlich zugeschnittenes Argumentarium
                gegen die UVI generiert. Mit diesem Link können Sie immer wieder darauf zugreifen.
            </div>
            <div className="argumentActions-wrapper">
                <div className="argumentActionBtn" onClick={() => copyCode()}>
                    <img src={Copy} alt="Copy Icon"/>
                    <div className="argumentActionBtn-text">
                        Adresse kopieren
                    </div>
                </div>
                <div className="argumentActionBtn" onClick={() => bookmarkPage()}>
                    <img src={Bookmark} alt="Bookmark Icon"/>
                    <div className="argumentActionBtn-text">
                        Lesezeichen speichern
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArgumentActions;
