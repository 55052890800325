import React from 'react';
import './App.scss';
import Routes from './Routes';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};
ReactPixel.init('176246386800811', options);

const App = () => {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://io.fusedeck.net/d/tm.js?p=j4rrYSvuTw";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <Routes />
  );
}

export default App;
