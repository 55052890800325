import axios from 'axios';
import BASE_URL from '../../environment/environment';

class Service {

    getCategories() {
        return axios.get(`${BASE_URL}/api/category/`);
    }

    createArgumentarium(attributes) {
        return axios.post(`${BASE_URL}/api/argumentarium/`, attributes);
    }

    getArgumentarium(code) {
        return axios.get(`${BASE_URL}/api/argumentarium/?code=${code}`);
    }

    rateArgument(body) {
        return axios.put(`${BASE_URL}/api/argument/`, body);
    }
}

export default Service;