import React, { useState, useContext } from 'react';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import './argumentCard.scss';
// Store
import Store from '../../../../core/stores/Store';
// Components
import ArgumentRating from '../ArgumentRating/ArgumentRating';
import ArgumentRelevance from '../ArgumentRelevance/ArgumentRelevance';
// Images
import { ReactComponent as LinkArrow } from '../../../../images/link.svg';
import Dropdown from '../../../../images/dropdown.svg';
import { observer } from 'mobx-react';

const ArgumentCard = observer(({argument}) => {

    const [open, setOpen] = useState(false);
    const store = useContext(Store);

    const ArgumentToggler = () => {
        return (
            <div className="argumentCard-toggle" onClick={() => setOpen(!open)}>
                <img src={Dropdown} alt="Dropdown arrow"/>
            </div>
        )
    }

    const ArgumentBadge = () => {
        if (!argument.is_new) return null;

        return (
            <div className="argumentBadge">
                New
            </div>
        )
    }

    const ArgumentDescription = () => {
        return (
            <div className="argumentCard-text">
                {argument.description}
            </div>
        )
    }

    const ArgumentResources = () => {
        if (argument.references.length === 0) return null;

        return (
            <div className="argumentCard-resources">
                <div>Quellen:</div>
                {argument.references.map((reference, i) => {
                    return (
                        <div key={i}>
                            <a href={reference} target="_blank" rel="noopener noreferrer">
                                <LinkArrow />
                                {reference}
                            </a>
                        </div>
                    )
                })}
            </div>
        )
    }

    const getQuote = () => {
        return argument.title + "\n" + argument.description;
    }
    var mehr_text = !open ? 'Mehr...' : '';
    return (
        <div className={`argumentCard ${open && 'open'}`}>
            <ArgumentToggler />

            <div className="argumentCard-header">
                <div className="argumentCard-title" onClick={() => setOpen(!open)}>
                    <ArgumentBadge />
                    {argument.title} {mehr_text}
                </div>
                <ArgumentRelevance perc={argument.relevance}/>
            </div>

            <div className={`argumentCard-content ${open && 'open'}`}>
                <ArgumentDescription />

                <ArgumentResources />

            </div>

            {open &&
                <>
                <FacebookShareButton url={window.location.href} quote={getQuote()}>
                    <FacebookIcon size="20" round />
                    Argument teilen
                </FacebookShareButton>
                <ArgumentRating
                    isRated={argument.is_rated}
                    onRate={(rating) => store.rateArgument(argument.id, rating)}/>
                </>
            }
        </div>
    )
})

export default ArgumentCard;
